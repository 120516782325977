@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import 'variables';
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';
@import '~bootstrap/scss/bootstrap';
@import 'fonts';
@import 'functions';
@import '~@mdi/font/scss/materialdesignicons';

/* === Template mixins === */
@import 'mixins/animation';
@import 'mixins/badges';
@import 'mixins/buttons';
@import 'mixins/misc';
@import 'mixins/color-functions';
@import 'mixins/cards';
@import 'mixins/blockqoute';
@import 'mixins/popovers';
@import 'mixins/tooltips';
@import 'mixins/no-ui-slider';

/* === Core Styles === */
@import 'typography';
@import 'reset';
@import 'responsive';
@import 'misc';
@import 'utilities';
@import 'demo';
@import 'spinner';

/* === Components === */

// @import 'components/widgets';
@import 'components/forms';
@import 'components/checkbox-radio';
@import 'components/icons';
@import 'components/tables';
// @import 'components/accordions';
@import 'components/buttons';
@import 'components/breadcrumbs';
@import 'components/badges';
@import 'components/cards';

@import 'components/bootstrap-alerts';
@import 'components/tabs';
@import 'components/dropdowns';
@import 'components/pagination';
@import 'components/loaders/loaders';
@import 'components/timeline';
@import 'components/landing-screens/auth';

/* === Dashboard === */
@import 'dashboard';
@import 'notification-ripple';

/** === Public === */
@import 'public';

/* === Layout === */
@import 'navbar';
@import 'sidebar';
@import 'footer';
@import 'layout';
@import 'settings-panel';
