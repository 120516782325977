.milestone-parent {
  .milestone-item {
    display: flex;
    position: relative;
    background-color: transparent;
    &::before {
      height: 100%;
      width: 1px;
      background-color: #d8dce2;
      content: '';
      display: block;
      position: absolute;
      left: 17.5px;
      bottom: -17.5px;
    }
    .milestone-icon {
      height: 35px;
      width: 35px;
      position: relative;
      z-index: 9;
      background-color: #d0d8f9;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 22px;
      }
    }
    .milestone-detail {
      flex: 0 0 calc(100% - 35px);
      padding-left: 15px;
      span {
        font-size: 13px;
      }
    }
  }
}
