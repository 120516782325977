.file-uploader {
  label {
    width: 100%;
    min-height: 120px;
    padding: 10px;
    border: 1px dashed var(--success);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    border-radius: 5px;
    i {
      font-size: 45px;
    }
    &.flat {
      border: 1px solid var(--gray-lighter);
    }
    span {
      font-size: 14px;
    }
    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }
  .add-document-action {
    height: 100px;
    width: 100px;
    margin-right: auto;
    border-radius: 5px;
    border: 1px dashed var(--success);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    i {
      font-size: 32px;
    }
    span {
      text-align: center;
    }
  }
}

.form-statement {
  font-size: 14px;
}

label {
  font-size: 14px;
}
