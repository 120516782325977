select.form-control {
  //   border-radius: 4px !important;
  border: 1px solid #dadade;
  outline: none;
}

.input-group-prepend {
  select {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
