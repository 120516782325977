/* Miscellanoeous */
body,
html {
	overflow-x: hidden;
	padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
	background: $content-bg;
	font-family: $type1;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
	overflow: auto;
}

.page-body-wrapper {
	min-height: calc(100vh - #{$navbar-height});
	padding-top: $navbar-height;
	@include display-flex();
	@include flex-direction(row);
	padding-left: 0;
	padding-right: 0;

	&.full-page-wrapper {
		min-height: 100vh;
		padding-top: 0;
		.main-panel {
			width: 100%;
			transition: none;
			.content-wrapper {
				padding: 0;
				> div {
					height: 100%;
				}
			}
		}
	}
}

.main-panel {
	transition: width $action-transition-duration
			$action-transition-timing-function,
		margin $action-transition-duration $action-transition-timing-function;
	width: calc(100% - #{$sidebar-width-lg});
	min-height: calc(100vh - #{$navbar-height});
	@include display-flex();
	@include flex-direction(column);
	@media (max-width: 991px) {
		margin-left: 0;
		width: 100%;
	}
	&.main-panel-only {
		transition: none;
	}
}

.content-wrapper {
	background: $content-bg;
	padding: 3rem 3rem;
	width: 100%;
	@include flex-grow(1);
}

.container-scroller {
	overflow: hidden;
}

pre {
	background: color(gray-lighter);
	padding: 15px;
	font-size: 14px;
}

code {
	padding: 5px;
	color: theme-color(danger);
	font-weight: $font-weight-light;
	font-size: $default-font-size;
	border-radius: 4px;
}

.page-header {
	@extend .d-flex;
	@extend .justify-content-between;
	@extend .align-items-center;
	margin: 0 0 1.5rem 0;
	.breadcrumb {
		border: 0;
		margin-bottom: 0;
	}
}
.page-title {
	color: $black;
	font-size: 1.125rem;
	margin-bottom: 0;
	.page-title-icon {
		display: inline-block;
		width: 36px;
		height: 36px;
		border-radius: 4px;
		text-align: center;
		box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
		i {
			font-size: 0.9375rem;
			line-height: 36px;
		}
	}
}
